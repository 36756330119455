//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note__border-color: $color-yellow-light3 !default;
$message-global-note__color: $text__color !default;

$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;

$message-global-caution__background: $color-red9 !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white !default;

$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;

//  Header
$header__background-color: false !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;

$addto-color: $text__color__muted !default;
$addto-hover-color: $primary__color !default;

$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$button__shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: false !default;


body {
  @include lib-css(background-color, $page__background-color);
}

img.lazy[src=""] {
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0;
}

img.lazy:not([src=""]) {
  opacity: 1;
}


.loading {
  background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}


.info-text {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  text-align: justify;
  font-weight: $font-weight__light;
}

//
//  Header
//  ---------------------------------------------

.page-header {
  @include lib-css(background-color, $header__background-color);
  border-bottom: 1px solid $border-color__base;
  margin-bottom: $indent__base;

  .panel.wrapper {
    background: $color-main;
  }
}

.header {
  &.panel {
    > .header.links {
      > li {
        &.authorization-link {
          a {
            &:before {
              @extend %fa-icon;
              @extend .fas;
            }
          }
        }
      }
    }
  }

  &.content {
    @extend .abs-add-clearfix;
    padding-top: $indent__s;
    position: relative;

    .info {
      text-align: center;

      span {
        display: inline-block;
        padding: 10px;

        i {
          margin-right: 10px;

          &:before {
            @extend %fa-icon;
          }
        }

        &.phone {
          font-size: 18px;
          font-weight: $font-weight__bold;
          color: $color-dark03;

          i {
            padding: 3px 8px;
            border-radius: 50%;
            background: $color-main;

            &:before {
              color: $color-white;
              @extend .fas;
              @include fa-icon-flip(-1, 1, 0);
              content: fa-content($fa-var-phone);
              font-size: 14px;
            }
          }
        }

        &.email {
          font-size: 14px;
          color: $color-gray03;

          i {
            &:before {
              @extend .far;
              content: fa-content($fa-var-envelope);
            }
          }
        }
      }
    }
  }
}

.logo {
  float: left;
  margin: 0 0 $indent__s $indent__xl;
  max-width: 50%;
  position: relative;
  z-index: 5;

  img {
    display: block;
  }

  .page-print & {
    float: none;
  }
}

.page-main {
  > .page-title-wrapper {
    .page-title + .action {
      margin-top: $indent__l;
    }
  }
}

.action.skip {
  &:not(:focus) {
    @extend .abs-visually-hidden;
  }

  &:focus {
    @include lib-css(background, $color-gray94);
    @include lib-css(padding, $indent__s);
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
  }
}

.action-skip-wrapper {
  height: 0;
  position: relative;
}
#switcher-currency {
  line-height: 54px;
  color: $color-white;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
  p {
    margin: 0;
  }

  &.noscript,
  &.cookie {
    @include lib-message($_message-type: global-note);
    margin: 0;
  }

  &.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;

    .actions {
      margin-top: $indent__s;
    }
  }

  &.demo {
    @include lib-message($_message-type: global-caution);
    margin-bottom: 0;
    text-align: center;
  }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
  @include lib-css(background-color, $footer__background-color);
  margin-top: auto;
}

.newsletter-left {
  font-weight: $font-weight__light;
  padding-top: 15px;
  .title {
    font-size: 18px;
    font-weight: $font-weight__semibold;
    padding-right: 5px;
    &:before {
      @extend %fa-icon;
      @extend .far;
      font-size: 35px;
      line-height: 35px;
      vertical-align: middle;
      content: fa-content($fa-var-envelope);
      padding-right: 15px;
    }
  }
}

.footer {
  background: $color-dark;
  color: $color-white;

  .info {
    padding-top: 20px;

    .address-block {
      padding-bottom: 10px;

      .item {
        &.title {
          font-size: 18px;
          font-weight: $font-weight__semibold;
          position: relative;
        }

        &.content {
          padding-top: 5px;

          li {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 30px;
            color: $color-white;

            i {
              &:before {
                @extend %fa-icon;
                @extend .fas;
                color: $color-white;
                position: absolute;
                left: 0;
                top: 9px;
              }
            }

            &.address {
              padding-bottom: 0;

              i {
                &:before {
                  content: fa-content($fa-var-map-marker-alt);
                }
              }
            }

            &.phone {
              padding-bottom: 0;

              i {

                &:before {
                  @include fa-icon-flip(-1, 1, 0);
                  content: fa-content($fa-var-phone);
                }
              }

            }

            &.email {
              i {
                &:before {
                  content: fa-content($fa-var-paper-plane);
                }
              }
            }
          }
        }
      }
    }

    .collapsible-block {
      @include lib-data-accordion__base();

      .item {
        &.title {
          font-size: 18px;
          font-weight: $font-weight__semibold;
          border-top: 1px solid $color-gray04;
          padding-top: 5px;
          padding-bottom: 5px;
          position: relative;

          &:before {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: inherit;
            color: inherit;
            font-size: 30px;
            content: $icon-pointer-down;
            font-family: "icons-blank-theme";
            vertical-align: middle;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            position: absolute;
            right: 0;
            top: -5px;
          }
        }

        &.content {
          padding-top: 5px;

          li {
            padding-top: 5px;
            padding-bottom: 5px;

            a {
              color: $color-white;
            }
          }
        }
      }

      &.active {
        .item {
          &.title {
            &:before {
              content: $icon-pointer-up;
            }
          }
        }
      }
    }
  }

  ul {
    @extend .abs-reset-list;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  .links {
    > li {
      margin: 0 0 8px;
    }
  }

  &.content {
    padding-bottom: 25px;
    padding-top: 25px;

    .switcher-store {
      margin: 0 0 30px;
    }
  }

  .copyright {
    text-align: left;
    color: $color-white;
    .copyright {
      display: block;
      margin-top: 10px;
      border-top:1px solid $color-footer-line;
      padding: 15px 0;
    }
  }
}

.page-header,
.page-footer {
  .switcher {
    margin-right: 10px;

    .options {
      @include lib-dropdown(
              $_dropdown-actions-padding: 0,
              $_dropdown-list-item-padding: 0,
              $_dropdown-toggle-icon-content: $icon-down,
              $_dropdown-toggle-active-icon-content: $icon-up,
              $_icon-font-text-hide: true,
              $_icon-font-size: 22px,
              $_icon-font-line-height: 22px,
              $_dropdown-list-min-width: 160px
      );

      ul.dropdown {
        a {
          display: block;
          padding: 8px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    li {
      font-size: $font-size__s;
      margin: 0;
    }

    .label {
      @extend .abs-visually-hidden;
    }

    strong {
      font-weight: $font-weight__regular;
    }
  }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
  .widget.block:not(:last-child),
  .widget:not(:last-child) {
    @extend .abs-margin-for-blocks-and-widgets;
  }
}

.widget {
  clear: both;

  .block-title {
    @extend .abs-block-title;
  }
}

.page-header,
.page-footer {
  .widget.block {
    @include lib-css(margin, $indent__base 0);
  }
}

.no-display {
  @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
  td {
    padding: 0;
  }
}

@include max-screen($screen__m) {
  .bottom-container {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
  }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

  html,
  body {
    height: 100%; // Stretch screen area for sticky footer
  }

  .page-header {
    .panel.wrapper {
      background: $color-dark;
    }
  }

  .page-wrapper {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-prefix-flex-direction(column);
    min-height: 100%; // Stretch content area for sticky footer

    > .breadcrumbs,
    > .top-container,
    > .widget {
      box-sizing: border-box;
      width: 100%;
    }

    .ie10 &,
    .ie11 & {
      height: 100%;
    }
  }

  .navigation ul {
    padding: 0 8px;
  }

  .header {
    &.panel {
      > .header.links {
        float: left;
        font-size: 0;
        line-height: 54px;
        @include lib-list-inline();
        margin-left: auto;
        text-transform: uppercase;

        > li {
          font-size: $font-size__base;
          margin: 0 15px 0 0;

          &.welcome,
          a {
            font-size: 12px;
            color: $color-white;
          }

          &.welcome {
            a {
              @include lib-css(padding-left, $indent__xs);
            }
          }

          &.authorization-link {
            a {
              &:before {
                font-size: inherit;
                content: fa-content($fa-var-unlock-alt);
                color: $color-main;
                padding-right: 10px;
              }
            }
          }

          &.wishlist {
            display: none;
          }

          a {
            &.my-account-link {
              &:before {
                font-family: $icomoon-font-family;
                font-size: inherit;
                content: $icon-zeta-user;
                color: $color-main;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }

    &.content {
      @extend .abs-add-clearfix-desktop;
      padding: 50px 15px 0;
      min-height: 130px;
      .info {
        float: right;

        padding-right: 15px;
        span {
          display: block;
          padding: 5px;
        }
      }
    }
  }

  .page-header {
    border: 0;
    margin-bottom: 0;

    .panel.wrapper {

    }

    .header.panel {
      @extend .abs-add-clearfix-desktop;
      padding-bottom: 0;
      padding-top: 0;
    }

    .switcher {
      display: inline-block;
    }
  }

  .page-main {
    > .page-title-wrapper {
      .page-title {
        display: inline-block;
      }

      .page-title + .action {
        float: right;
        margin-top: $indent__base;
      }
    }
  }

  .logo {
    width: 25%;
    margin: 0;
    float: none;
    img {
      max-height: inherit;
      padding: 15px 10px;
    }
  }

  .footer {
    .info {
      padding: 40px 15px 20px 15px;
      .address-block {
        .item {
          &.title {
            padding-bottom: 15px;

            &:after {
              height: 0.1rem;
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 30px;
              background: $color-main;
              content: '';
            }
          }

          &.content {
            padding-top: 20px;

            li {
              padding-top: 5px;
              padding-bottom: 15px;
            }
          }
        }
      }

      .collapsible-block {
        @include lib-data-accordion__base();

        .item {
          &.title {
            font-size: 18px;
            color: $color-white;
            font-weight: $font-weight__semibold;
            border: none;
            padding-top: 0;
            padding-bottom: 15px;

            &:before {
              content: '';
            }

            &:after {
              height: 0.1rem;
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 30px;
              background: $color-main;
              content: '';
            }
          }

          &.content {
            padding-top: 20px;

            li {
              padding-top: 5px;
              padding-bottom: 15px;
            }
          }
        }

        &.active {
          .item {
            &.title {
              &:before {
                content: '';
              }
            }
          }
        }
      }
    }

    &.content {
      .block {
        float: right;
      }

      .links {
        display: inline-block;
        margin-bottom: 20px;
        padding: 0 50px 0 0;
        vertical-align: top;
      }

      .switcher.store {
        display: inline-block;
        padding-right: 50px;
        vertical-align: top;
      }
    }

    .copyright {
      @extend .abs-add-clearfix-desktop;
      .copyright {
        padding: 15px 10px;
      }
    }
  }



  .bottom-container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: $layout__max-width;
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
  }

  .page-layout-2columns-left .sidebar-additional, .page-layout-2columns-right .sidebar-additional {
    width: 25%;
  }

  .page-layout-2columns-left .sidebar-main, .page-layout-2columns-right .sidebar-main, .page-layout-3columns .sidebar-main {
    width: 25%;
  }

  .page-layout-2columns-left .column.main, page-layout-2columns-right .column.main  {
    width: 75%;
  }


}

@include min-screen($screen__l) {
  .header {
    .logo {
      margin: 5px -13px 25px 13px;
      padding: 0;
      float: left;
      img {
        padding: 0;
      }
    }
  }
  .footer {
    .info {
      .address-block {
        padding-left: 25%;
      }
    }
  }
}



