$product-grid-items-per-row-layout-default: 2 !default;

$product-grid-items-per-row-layout-1-screen-s: 3 !default;
$product-grid-items-per-row-layout-1-screen-m: 4 !default;
$product-grid-items-per-row-layout-1-screen-l: 4 !default;

$product-grid-items-per-row-layout-2-left-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l: '' !default;

$product-grid-items-per-row-layout-2-right-screen-s: 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m: 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l: '' !default;

$product-grid-items-per-row-layout-3-screen-s: 3 !default;
$product-grid-items-per-row-layout-3-screen-m: '' !default;
$product-grid-items-per-row-layout-3-screen-l: '' !default;

$product-grid-items-padding: 0 $indent__base $indent__base !default;
$product-grid-items-margin: 0 0 $indent__s !default;

$product-name-text-decoration: none !default;
$product-name-text-decoration-hover: $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size: 26px !default;
$product-h1-margin-bottom-desktop: $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/popup';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
  text-decoration: line-through;
}

.prices-tier {
  .price-container {
    .price-including-tax {
      + .price-excluding-tax {
        &:before {
          content: '(' attr(data-label) ': ';
        }

        &:last-child:after {
          content: ')';
        }
      }
    }

    .weee[data-label] {
      display: inline;

      .price {
        @include lib-font-size(14);
        color: $color-gray07;
      }

      &:before {
        content: '(';
      }

      &:after {
        content: attr(data-label) ')';
      }
    }
  }
}

.actual-price {
  font-weight: $font-weight__semibold;
}

.product.name a {
  @extend .abs-product-link;
}

.category-image {
  .image {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.category-image,
.category-description {
  margin-bottom: $indent__base;
}

.labels,
.actions-secondary {
  .label {
    text-transform: uppercase;
    background: $color-gray07;
    color: $color-white;
    float: left;
    font-size: 12px;
    font-weight: $font-weight__bold;
    text-align: center;
    margin-right: 5px;
    padding: 2px 5px;

    &.promo {
      background: $color-main;
    }

    &.new {
    }

    &.avail {
      background: $color-avail;
    }

    &.rec {
    }
  }
}

.labels {
  clear: both;
  padding-top: 20px;

  .label {
    float: right;
    padding: 5px 15px;

    &:first-child {
      margin-right: 0;
    }
  }
}


//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
  display: inline-block;
  max-width: 100%;
}

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
  .product.photo .photo.image {
    @extend .abs-adaptive-images-centered;
  }

  .placeholder .photo.container {
    max-width: 100%;
  }

  .notice {
    @include lib-css(color, $text__color__muted);
    @include lib-font-size($font-size__s);
    margin: $indent__s 0;
  }

  .product.thumbs {
    margin: $indent__base 0 $indent__l;
  }

  .items.thumbs {
    @include lib-list-inline();

    .active {
      display: block;
      line-height: 1;
    }
  }
}

.product.info.detailed {
  clear: both;
  margin-bottom: 30px;
  padding-top: 15px;

  .item.title {
    color: $color-dark01;
    text-transform: uppercase;

    .switch {
      border: none;
      background: transparent;
      font-size: 1.8rem;
      font-weight: $font-weight__semibold;
      padding-left: 0;
    }
  }

  .item.content {
    border-top: 1px solid $color-gray14;
    color: $color-dark02;
    padding-left: 0;
    margin-top: 50px;
    line-height: 28px;
  }
}

.additional-attributes-wrapper {
  padding-top: 20px;
  margin-top: 20px;

  .additional-attributes {
    table-layout: fixed;
    width: 100%;
    @include lib-table-resize(
            $_th-padding-left: 0,
            $_th-padding-right: 0,
            $_th-padding-bottom: 5,
            $_td-padding-bottom: 5
    );

    tr {
      color: $color-gray07;
      font-weight: $font-weight__regular;
      padding-bottom: 10px;
      border-bottom: 1px dotted $color-gray15;

      th {
        &.label {
          font-weight: $font-weight__semibold;
          width: auto;
          color: $color-dark01;

          &:after {
            content: ':';
          }
        }
      }

      td {

        &.data {
          font-weight: $font-weight__regular;

          text-transform: uppercase;
          text-align: right;
          width: 100%;
        }
      }

      .col {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.catalog-product-view {
  .page-title-wrapper {
    .page-title {
      line-height: $line-height__base;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: $font-weight__semibold;
    }
  }

  .stock {
    &.available,
    &.unavailable {
      display: inline-block;
      font-weight: $font-weight__semibold;
      margin-right: $indent__base;
      text-transform: uppercase;
      vertical-align: top;
    }
  }
  
  .top-right{
    @include flexbox();
    @include align-items(center);
    .manufacturer {
      text-align: right;
      @include flex-order(2);
    }
    .product-info-stock-sku {
      text-align: right;
      @include flex-grow(1);
      @include flex-order(1);
    }

  }


  .product {
    &.attribute {
      &.sku {
        display: inline-block;
        vertical-align: top;
        background: $color-light-grey;
        padding: 5px 10px;
        @include lib-css(color, $color-gray07);

        > .value {
          display: inline-block;
          vertical-align: top;
          word-break: break-all;
          font-weight: $font-weight__regular;
        }

        .type {
          margin-right: $indent__xs;
          font-weight: $font-weight__regular;

          &:after {
            content: ':';
          }
        }
      }

      &.overview {
        margin: $indent__base 0;
      }
    }

    &.alert {
      margin: $indent__s 0;
    }
  }

  .price-box {
    margin-top: $indent__s;
    color: $color-main;
  }

  .product-reviews-summary .reviews-actions {
    @include lib-font-size($font-size__base);
  }
}

.product-options-wrapper {
  .fieldset-product-options-inner {
    .legend {
      @include lib-css(font-weight, $font-weight__semibold);
      @include lib-css(margin, 0 0 $indent__xs);
      @include lib-font-size(14px);
      border: none;
      display: inline-block;
      float: none;
      padding: 0;
    }

    //  Date & Time custom option (Affect Time that goes only after Date)
    input.datetime-picker {
      ~ select.datetime-picker {
        margin-top: $indent__s;
      }
    }

    &.required,
    &._required {
      .legend {
        &:after {
          content: '*';
          @include lib-typography(
                  $_font-size: $form-field-label-asterisk__font-size,
                  $_color: $form-field-label-asterisk__color,
                  $_font-family: $form-field-label-asterisk__font-family,
                  $_font-weight: $form-field-label-asterisk__font-weight,
                  $_line-height: $form-field-label-asterisk__line-height,
                  $_font-style: $form-field-label-asterisk__font-style
          );
          @include lib-css(margin, $form-field-label-asterisk__margin);
        }
      }
    }
  }

  .field {
    .note {
      display: block;
    }

    .price-notice {
      @extend .abs-adjustment-incl-excl-tax;
    }
  }
}

.product-info-main,
.product-options-bottom {
  .price-box {
    .price-including-tax + .price-excluding-tax,
    .weee + .price-excluding-tax,
    .weee {
      @include lib-font-size(14);
      line-height: 14px;
      margin-bottom: $indent__xs;
      margin-top: 10px;

      .price {
        @include lib-font-size(14);
        font-weight: 400;
      }
    }

    .price-wrapper .price {
      @include lib-font-size(18);
      font-weight: $font-weight__semibold;
    }

    .price {
      white-space: nowrap;
    }

    .price-label {
      display: none;
    }
  }

  .special-price {
    display: block;
    margin: $indent__s 0;

    .price-container {
      @include lib-font-size(14);
    }

    .price-label + .price-wrapper {
      display: inline-block;
    }
  }

  .old-price,
  .special-price {
    .price-label {
      &:after {
        content: ': ';
      }
    }
  }


  .box-tocart {
    margin: $indent__base 0;

    .label {
      display: inline-block;
      font-weight: $font-weight__regular;
      color: $color-gray1;
      margin-right: 15px !important;
      &:after {
        content: ':';
      }
    }

    .field.qty {
      @include flexbox();
      @include align-items(center);
      margin: 0 0 5px;
    }

    #qty-update {
      @include flexbox();
      @include align-items(center);
      border: 1px solid $color-light-grey-4;

      input {
        border: none;
        height: 40px;
      }

      .qty-buttons {
        border-left: 1px solid $color-light-grey-4;
        padding: 4px 5px;

      }

      button {
        border: none;
        background: transparent;
        padding: 0;
        display: block;
        span {
          font-size: 12px;

          &:before {
            @extend %fa-icon;
            @extend .fas;
            color: $color-dark04;
          }

        }

        &.increase {
          span {
            &:before {
              content: fa-content($fa-var-chevron-up);
            }
          }
        }

        &.decrease {
          span {
            &:before {
              content: fa-content($fa-var-chevron-down);
            }
          }
        }
      }
    }

    .input-text.qty {
      $tocart-input-size: $button__line-height__l + 30px;
      height: 32px;
      text-align: center;
      width: 80px;
    }

    .actions {
      text-align: center;
      display: block;
    }

    .action.tocart {
      @extend .abs-button-l;
      width: 100%;
      text-transform: uppercase;
      border-radius: 0;
      border-color: $color-main;
      background: $color-main;

    }
  }

  .product-addto-links {
    margin: $indent__base 0;
    @include flexbox();

    .action {
      @include flex-grow(1);
      width: 50%;
      text-align: center;
      padding: 10px;
      color: $color-dark04;
      font-weight: $font-weight__bold;
      background: $color-light-grey;
      &.form-widget  {
        margin-right: 5px;
      }
      &.towishlist {
        margin-left: 5px;
      }
    }
  }

  .product-social-links {
    text-align: right;
  }


}

.prices-tier {
  @extend .abs-reset-list;
  @include lib-css(background, $sidebar__background-color);
  margin: $indent__s 0;
  padding: $indent__s (0.75 * $indent__base);

  .price-container {
    display: inline-block;
  }

  .price-including-tax,
  .price-excluding-tax,
  .weee {
    display: inline-block;

    .price {
      @include lib-font-size(14);
      font-weight: $font-weight__semibold;
    }
  }
}

.ui-dialog-titlebar-close {
  @include lib-button-as-link();
}

.block.related {
  .action.select {
    margin: 0 $indent__xs;
  }
}

.block.related, .block.upsell {
  .block-title {
    font-weight: $font-weight__bold;
    text-transform: uppercase;

    strong {
      font-size: 16px;
      padding-right: 20px;
    }
  }

  .product-item-info {
    margin-right: 2px;
  }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
  .product-items {
    .product-item {
      margin-bottom: $indent__base;
      position: relative;
    }

    .product-item-info {
      position: relative;
      width: auto;

      .product-item-photo {
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .product-item-name {
      margin-top: 0;
    }

    .product-item-details {
      margin: 0 0 0 85px;
    }

    .product-item-actions {
      display: block;
      margin-top: $indent__s;
    }

    .price-box {
      display: block;
      margin: 7px 0;
    }

    .text {
      margin-right: 8px;
    }

    .counter {
      @include lib-css(color, $primary__color__lighter);
      @include lib-font-size(12);
      white-space: nowrap;
    }

    .minilist {
      .price {
        display: inline;
        padding: 0;
      }

      .weee:before {
        display: inline-block;
      }
    }
  }

  .action {
    &.delete {
      @extend .abs-remove-button-for-blocks;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .subtitle {
    @extend .abs-no-display;
  }

  //
  //  Product images only
  //  ---------------------------------------------

  .product-items-images {
    @extend .abs-add-clearfix;
    margin-left: -$indent__xs;

    .product-item {
      @extend .abs-add-box-sizing;
      float: left;
      padding-left: $indent__xs;
    }
  }

  //
  //  Product names only
  //  ---------------------------------------------

  .product-items-names {
    .product-item {
      margin-bottom: $indent__s;
    }

    .product-item-name {
      margin: 0;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
  .additional-attributes-wrapper {
    .additional-attributes {
      tr {
        padding-bottom: 0;
        td {
          &.data {
            text-align: left;
          }
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .catalog-product-view {
    .column.main {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-prefix-flex-direction(column);
    }

    .product.media {
      @include lib-vendor-prefix-order(-1);
    }
  }

  .product-info-main .box-tocart {
    .actions {
      display: block;

      .action.tocart {
        @extend .abs-button-responsive-smaller;

      }
    }
  }

  .block.related {
    .action.select {
      display: block;
      margin: $indent__xs 0;
    }
  }

  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none;
  }

  .product.info.detailed {
    .item.content {
      padding-left: 15px;
      margin-top: 20px;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .product-info-main,
  .product-options-bottom {
    .box-tocart {
      display: block;

      .field.qty {

      }

      .actions {
        display: block;
        padding-top: 15px;
        text-align: center;
        vertical-align: bottom;
      }
    }
  }

  .product-info-main {
    .page-title-wrapper {
      .page-title {
        margin-top: 0;
      }
    }

    .product-info-stock-sku {
      padding-bottom: 35px;
    }

    .price-box {
      .price-wrapper {
        .price {
          font-size: 36px;
        }
      }

      .old-price {
        .price {
          font-size: 24px;
        }
      }
    }
  }

  .product.info.detailed {
    padding-top: 15px;
  }

  .sidebar {
    .product-items {
      .product-item-info {
        .product-item-photo {
          float: left;
          left: auto;
          margin: 0 $indent__s $indent__s 0;
          position: relative;
          top: auto;
        }
      }

      .product-item-details {
        margin: 0;
      }

      .product-item-actions {
        clear: left;
      }
    }
  }

  .product-add-form {
    @extend .abs-revert-field-type-desktop;
  }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
  .sidebar {
    .product-items {
      .product-item-info {
        .product-item-photo {
          float: none;
          left: 0;
          margin: 0;
          position: absolute;
          top: 0;
        }
      }

      .product-item-details {
        margin-left: 85px;
      }
    }
  }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
  .product-info-main {
    float: left;
  }

  .product.media {
    float: right;
    margin-bottom: $indent__m;
  }

  .page-layout-1column {
    .product-info-main {
      width: 50%;
    }

    .product.media {
      width: 48%;
    }
  }

  .page-layout-2columns-left,
  .page-layout-2columns-right,
  .page-layout-3columns {
    .product-info-main {
      width: 48%;
    }

    .product.media {
      width: 50%;
    }
  }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
  .action.print {
    float: right;
    margin: 15px 0;
  }
}

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto;
}

.table-comparison {
  table-layout: fixed;

  .cell.label.remove,
  .cell.label.product {
    span {
      @extend .abs-visually-hidden;
    }
  }

  .cell.label,
  td:last-child {
    border-right: $table__border-width $table__border-style $table__border-color;
  }

  .cell {
    padding: 15px;
    width: 140px;

    .attribute.value {
      overflow: hidden;
      width: 100%;
    }

    &.product.info,
    &.product.label {
      border-bottom: $table__border-width $table__border-style $table__border-color;
    }

    &.label {
      .attribute.label {
        display: block;
        width: 100%;
        word-wrap: break-word;
      }
    }

    &.attribute {
      @include lib-font-size(13);

      img {
        height: auto;
        max-width: 100%;
      }
    }
  }

  .product-item-photo {
    display: block;
    margin: 0 auto 15px;
  }

  .product-image-photo {
    margin-left: 0;
  }

  .product-item-actions,
  .price-box,
  .product.rating,
  .product-item-name {
    display: block;
    margin: 15px 0;
  }

  .product-addto-links {
    margin-top: 15px;

    .action.split,
    .action.toggle {
      @include lib-button-s();
    }

    .action.toggle {
      padding: 0;
    }
  }

  .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right;

    .action.delete {
      @extend .abs-remove-button-for-blocks;
    }
  }

  .product-item-actions {
    > .actions-primary {
      + .actions-secondary {
        margin-top: $indent__s;
      }
    }
  }

  .action {
    &.tocart {
      white-space: nowrap;
    }
  }
}

.comparison.headings {
  @include lib-css(background, $page__background-color);
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2;
}

.block-compare {
  .block-title {
    @extend .abs-block-title;
  }

  .product-item .product-item-name {
    margin-left: 22px;
  }

  .action {
    &.delete {
      @extend .abs-remove-button-for-blocks;
      left: -6px;
      position: absolute;
      top: 0;
    }

    &.compare {
      @extend .abs-revert-secondary-color;
    }
  }

  .counter {
    @extend .abs-block-items-counter;
  }

  .actions-toolbar {
    margin: 17px 0 0;
  }
}
