.footer {
  .social {
    text-align: center;
    i {
      color: $color-main;
      background: $color-white;
      padding: 5px 0;
      display: inline-block;
      border-radius: 50%;
      margin-right: 5px;
      font-size: 20px;
      width: 35px;
      height: 35px;
      text-align: center;
      @extend .transition-all;
      &:before {
        @extend %fa-icon;
        @extend .fab;
      }
      &:hover {
        color: $color-white;
        background: $color-main;
      }
    }

    .fb {
      i {
        &:before {
          content: fa-content($fa-var-facebook-f);
        }
      }
    }

    .in {
      i {
        &:before {
          content: fa-content($fa-var-linkedin-in);
        }
      }
    }

    .twitter {
      i {
        &:before {
          content: fa-content($fa-var-twitter);
        }
      }
    }

    .instagram {
      i {
        &:before {
          content: fa-content($fa-var-instagram);
        }
      }
    }

    .yt {
      i {
        &:before {
          content: fa-content($fa-var-youtube);
        }
      }
    }

    .pinterest {
      i {
        &:before {
          content: fa-content($fa-var-pinterest-p);
        }
      }
    }

    .vimeo {
      i {
        &:before {
          content: fa-content($fa-var-vimeo-v);
        }
      }
    }
  }
}

@include min-screen($screen__m) {
  .footer {
    .social {
      padding-top: 15px;
      text-align: right;
    }
  }
}

@include max-screen($screen__l) {

}

