#switcher-language {
  position: fixed;
  left: 50px;
  top: 5px;
  padding: 5px 15px;
  z-index: 200;
  color: $color-white;
  background: $color-dark01;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

@include max-screen($screen__m) {
  #switcher-language {
    display: none;
  }
}