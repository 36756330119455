$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background: $panel__background-color !default;

html {
  &:not(.nav-open) {
    .page-products {
      .columns {
        position: relative;
        z-index: 1;
      }
    }
  }
}


.products.wrapper ~ .toolbar {
  background: $color-gray02;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 50px;
  @include flexbox();
  @include align-items(center);
}

.toolbar {
  @extend .abs-add-clearfix;
  @include flexbox;
  @include align-items(center);
  color: $color-gray07;
  overflow: hidden;

  select {
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $color-gray07;
    box-shadow: none;
    height: 25px;
    border-radius: 10px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    padding-right: 2rem;
    line-height: 1.4rem;
    background: url('../images/arrow_down.png') 90% center no-repeat $color-gray06;
    background-size: auto;
    font-size: 1.2rem;
  }
}

.toolbar-amount {
  display: block;
  line-height: $toolbar-mode-icon-font-size + 2;
  margin: 0;
  padding: 0;

  .products.wrapper ~ .toolbar & {
  }
}

.toolbar-products {
  @extend .abs-add-clearfix;
  margin-bottom: $indent__xl;
  padding: $indent__s;
  text-align: center;
  background: $color-gray04;

  .pages {
    display: none;

    .products.wrapper ~ & {
      display: block;
      @include flex-grow(1);
    }
  }

  .limiter {
    display: none;

    .control {
    }
  }
}

.sorter {
  padding: 0;

  .products.wrapper ~ .toolbar & {
    display: none;
  }
}

.sorter-options {
  margin: 0 0 0 7px;
  width: auto;
}

.sorter-action {
  vertical-align: top;
  @include lib-icon-font(
                  $icon-arrow-up,
          $_icon-font-size: 25px,
          $_icon-font-line-height: 25px,
          $_icon-font-color: $header-icons-color,
          $_icon-font-color-hover: $header-icons-color-hover,
          $_icon-font-text-hide: true
  );
}

.sorter {
  .sort-desc {
    &:before {
      content: $icon-arrow-down;
    }
  }
}

.modes {
  display: none;
  &-mode {
    @extend %fa-icon;
    @extend .fas;
  }
  span {
    display: none;
  }
}

.limiter-options {
  width: auto;
}

.limiter-label {
  font-weight: 400;
}

.limiter {
  @include flexbox();
  @include align-items(center);

  .label {
    @include flex-order(1)
  }

  .control {
    @include flex-order(2)
  }

  .page-products .toolbar & {
    @include flexbox();
  }
}

@include max-screen($screen__xs) {
  .products.wrapper ~ .toolbar {
    display: block;
  }

  .limiter {
    .page-products ~ .toolbar & {
      display: block;
      padding-top: 10px;
    }
  }
}




//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .page-products {
    .columns {
      padding-top: 0;
      position: relative;
      z-index: 1;
    }
  }

  .toolbar {


    .products.wrapper ~ & .pages {
      float: left;
    }
  }

  .toolbar-amount {
    .page-products .toolbar & {
      @include flex-order(4);
      @include flex-grow(1);
      text-align: right;
    }

    .products.wrapper ~ .toolbar & {
      @include flex-order(1);
      @include flex-grow(0);
      text-align: left;
      position: absolute;
    }
  }


  .sorter {
    @include flex-order(2);
    padding-left: 15px;
  }

  .modes {
    display: block;
    @include flex-order(1);

    .products.wrapper ~ .toolbar & {
      display: none;
    }
  }

  .modes-label {
    @extend .abs-visually-hidden-desktop;
  }

  .modes-mode {
    color: $color-white;
    background: $color-gray10;
    float: left;
    padding: 7px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 2px;
    text-align: center;
    &:before {
      content: fa-content($fa-var-th-large);
      color: $color-white;
      font-size: 14px;
      line-height: 16px;
    }

    &:not(.active) {
      &:hover {
        color: $text__color__muted;
        background: darken($toolbar-element-background, 7%);
      }
    }


    &.active {
      background: $color-main;
    }
  }

  .mode-list {
    &:before {
      content: fa-content($fa-var-bars);
    }
  }

  .mode-filter {
    background: $color-dark01;
    border: none;
    &:before {
      content: fa-content($fa-var-filter);
    }
    &:hover {
      background: $color-dark01 !important;
      border: none;
    }
    &:focus {
      background: $color-dark01 !important;
      border: none;
    }
  }


  .limiter {
    padding-left: 15px;

    .products.wrapper ~ .toolbar & {
      display: none;

      .control {
        padding-left: 15px;
      }
    }

    .page-products .toolbar & {
      @include flex-order(3);

      .control {
        padding-left: 15px;
      }
    }
  }
}
