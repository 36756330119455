.pages {
    @include lib-pager();
    .item {
        &.current {
            .page {
                line-height: 1;
                border-radius: 50%;
            }
        }
    }
    .action {
        width: 25px;
        &:before {
            font-weight: 900 !important;
        }
        &.previous {
            margin-right: 0;
        }

        &.next {
            margin-left: 0;
        }
    }
}
