#mmm {
  li {
    &.parent {
      a {
        > .ui-menu-icon {
          &:after {
            @extend %fa-icon;
            @extend .fas;
          }
        }
      }
    }
  }
}

.menu-text {
  display: none;
}


@include max-screen($screen__m) {
  .nav-toggle {
    &:before {
      line-height: 20px;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

  .menu-text {
    font-size: 16px;
    background: $color-dark;
    color: $color-white;
    text-transform: uppercase;
    padding: 10px 15px;
    margin-bottom: 15px;
    display: block;
  }

  .navigation {
    background: $color-gray06;

    ul {
      padding: 0;
    }
  }

  #mmm {
    background: $color-light-grey;

    li {
      position: relative;
      @include flexbox();
      color: $color-dark02;

      &.level0 {
        margin: 0;

        &.active {
          > .level-top {
            color: $color-white;
            background: $color-main;
          }
        }

        &.has-active {
          > .level-top {
            color: $color-white;
            background: $color-main;
          }
        }
      }

      &.parent {
        a {
          @include flexbox();
          @include align-items(center);
          @include flex-grow(1);

          .ui-menu-icon {
            @include flex-order(2);
            @include align-self(self-end);

            &:after {
              content: fa-content($fa-var-chevron-right);
              color: inherit;
              font-size: 9px;
              padding-left: 5px;
            }
          }

          span {
            &:last-child {
              @include flex-order(1);
              @include flex-grow(1);
            }
          }
        }
      }

      &.level-top {
        a {
          @include flexbox();
          @include flex-grow(1);
          line-height: 1;
          font-weight: $font-weight__light;

          &.level-top {
            color: $color-dark02;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 15px;
            border-bottom: 1px solid $color-gray05;
            text-transform: lowercase;

            &:hover {
              color: $color-white;
              background: $color-main;
            }
          }
        }

        &.parent {
          a {
            padding-right: 5px;
          }

        }

        .submenu {
          padding: 0;
          width: 265px;

          .active {
            > a {
              border-color: $color-main;
            }
          }

          a {
            text-transform: lowercase;
            padding-right: 5px;
            color: $color-dark02;
          }
        }

      }
    }

  }

}