.block.filter {
    margin-bottom: 10px;

    .filter-title {
        margin-bottom: $indent__base;

        strong {
            font-size: 18px;
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__semibold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    background: $color-gray06;
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        padding: 10px 0;
        word-break: break-all;
        @include flexbox();
        @include align-self(center);
        font-weight: $font-weight__semibold;
        font-size: 16px;
        border: none;
        position: relative;
        color: $color-dark01;
        background: $color-gray06;
        padding-left: 15px;
        &:after {
            @extend .fas;
            @extend %fa-icon;
            content: fa-content($fa-var-angle-down);
            font-size: 16px;
            position: absolute;
            right: 30px;
        }
        &[aria-expanded="true"] {
            &:after {
                content: fa-content($fa-var-angle-up);
            }
        }

    }

    .filter-options-content {
        margin: 0 0 $indent__m;

        .item {
            margin-bottom: 3px;
            font-weight: $font-weight__light;
            a {
                color: $color-gray11;
            }
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__semibold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }
}
