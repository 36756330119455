.formwidget {
  &.field {
    clear: both;
  }
  &.modal {
    display: none;
  }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
  .action.form-widget {
    font-size: 14px;
    line-height: inherit;
    font-weight: $font-weight__bold;
    vertical-align: top;
    border: none;
    color: $color-dark04;
    &:hover {
      background: transparent;
      border: none;
      color: $color-dark04;
    }
      &:before {
        @extend %fa-icon;
        @extend .fas;
        color: $color-main;
        content: fa-content($fa-var-envelope);
        padding-right: 10px;
      }
  }
}