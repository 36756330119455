$icomoon-font-family: "zeta";

$icon-zeta-arrow-circle-left: "\e900";
$icon-zeta-arrow-circle-right: "\e901";
$icon-zeta-arrow-down: "\e902";
$icon-zeta-triangle-right: "\e903";
$icon-zeta-cart: "\e904";
$icon-zeta-cart-plus: "\e905";
$icon-zeta-env: "\e906";
$icon-zeta-grid: "\e907";
$icon-zeta-list: "\e909";
$icon-zeta-phone: "\e90b";
$icon-zeta-return: "\e90d";
$icon-zeta-truck: "\e90e";
$icon-zeta-user: "\e90f";

