//
//  Variables
//  _____________________________________________

$checkout-sidebar__margin: $indent__base !default;
$checkout-sidebar__margin__xl: 46px !default;
$checkout-sidebar__columns: 4 !default;

.opc-sidebar {
  .price {
    &-including-tax:after {
      font-size: 12px;
    }
  }
}

.opc-block-summary {
  .product-item {
    .price-including-tax + .price-excluding-tax {
      .price {
        font-size: 1.2rem;
      }
      &:after {
        font-size: 1.2rem;
      }
    }
  }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .opc-sidebar {
    @include lib-css(margin, $checkout-sidebar__margin__xl 0 $checkout-sidebar__margin);
    @include lib-layout-column(2, 2, $checkout-sidebar__columns);
    width: 33.33333333%;
  }

  .opc-wrapper {
    width: 66.66666667%;
  }


}
