.news-container {
  .news-item {
    margin-bottom: 15px;
  }
  h3 {
    @include flexbox();
    @include flex-grow(1);
    margin-top: 45px;
    margin-bottom: 45px;
    font-size: 24px;
    font-weight: $font-weight__regular;
    color: $color-dark01;
    &:after {
      content: '';
      @include flex(1);
      margin: auto;
      height: 0;
      border-top: solid $color-light-grey-3 3px;
    }
    span {
      padding-right: 15px;
    }
  }
  .news {
    padding-left: 0;
    padding-bottom: 15px;
  }
  .news-text {
    font-weight: $font-weight__light;
    font-size: 12px;
    line-height: 24px;
    color: $color-gray13;
    padding-right: 5px;
  }
  .news-img {
    position: relative;
    img {
      width: 100%;
      display: block;
    }
  }
  h4 {
    color:$color-dark01;
    font-size: 18px;
    font-weight: $font-weight__regular;
  }
  a {
    &.readmore {
      @extend .transition-all;
      display: inline-block;
      margin-top: 15px;
      font-weight: $font-weight__regular;
      line-height: 12px;
      font-size: 12px;
      color: $color-white;
      background: $color-main;
      padding: 8px 15px;
      text-transform: uppercase;
      position: absolute;
      right: 0;
      bottom: 0;
      &:hover {
        color: $color-white;
        background: $color-gray07;
      }
    }
  }
}

@include min-screen($screen__s) {
  .news-container {
    h4 {
      margin-top: 0;
    }
    .news-item {
      .news {
        padding-left: 15px;
      }
    }
  }
}

@include min-screen($screen__m) {

}

@include min-screen($screen__l) {
  .news-container {
    h4 {
      margin-top: 2rem;
    }
    .news-item {
      .news {
        padding-left: 0;
      }
    }
  }
}