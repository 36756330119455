.breadcrumbs {
    @include lib-breadcrumbs();
    @include flexbox();
    &:after {
        content: '';
        @include flex(1);
        margin: auto;
        height: 0;
        border-top: solid $color-gray05 1px;
    }
    padding-bottom: 5px;
    .item {
        &:last-child {
            text-transform: none;
            padding-right: 15px;
        }
    }
}

@include min-screen($screen__m) {
    .breadcrumbs {
        padding-bottom: 20px;
        margin-bottom: 0;
    }
}
