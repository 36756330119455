#date_range {
  margin-bottom: 20px;
  form {
    @include flexbox();
    @include align-items(center);
    input {
      width: auto;
      text-align: right;
    }
    .field-row {
      padding-right: 15px;
    }
    button {
      margin-left: 15px;
    }
  }
}