.subaccount-account-edit {
  .fieldset {
    & > .field, > .fields {
      & > .label {
        display: block;
        text-align: left;
        width: 100%;
        float: none;
      }
    }

    .control {
      position: relative;
      padding-right: 25px;
    }

    .choice {
      position: relative;
      @include flexbox();
      @include align-items(center);

      &:before {
        width: auto;
        padding: 0;
      }

      .field-tooltip {

      }

      .label {
        padding-left: 10px;
        width: auto;
      }

    }

    input {
      float: left;
    }

    .budget {
      text-align: center;
      .field {
        font-size: 20px;
      }
    }
  }
}


.subaccount-account-index,
.subaccount-account-edit {
  .actions-toolbar {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.my-budget {
  text-transform: none !important;

  a {
    font-weight: $font-weight__light !important;

    span {
      &.budget {
        font-weight: $font-weight__semibold;
      }
    }
  }

}

.subaccount-budget-edit {
  .actions-toolbar {
    margin-bottom: 20px;

    .primary {
      margin-right: 15px;
    }
  }

  .actions {
    text-align: right;

    .action {
      margin-bottom: 15px;
    }
  }

  #items {
    input {
      text-align: right;
      width: 55px;
    }
  }
}