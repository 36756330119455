.modal-layered {
  right: 10%;
  left: 0;
}

@include min-screen($screen__xs) {
  .modal-layered {
    right: 25%;
  }
}

@include min-screen($screen__m) {
  .modal-layered {
    right: 50%;
  }
}

@include min-screen($screen__l) {
  .modal-layered {
    right: 66%;
  }
}