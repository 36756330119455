//  Prices
@mixin price-style-1() {
    .price {
        &-tier_price .price-excluding-tax,
        &-tier_price .price-including-tax {
            display: inline;
        }
    }
}

@mixin price-style-2() {
    .price {
        &-including-tax,
        &-excluding-tax {
            display: inline !important;
        }

        &-including-tax:before {
            content: ' / ';
        }

        &-including-tax:after {
            content: '("attr(data-label)")';
        }
    }
}

@mixin price-style-3() {
    .price-including-tax,
    .price-excluding-tax {
        display: block;
        @include lib-font-size(18);
        line-height: 1;

        .price {
            font-weight: $font-weight__light;
        }

        .cart-tax-total {
            @extend .abs-tax-total;
            &-expanded {
                @extend .abs-tax-total-expanded;
            }
        }
    }

    .price-including-tax + .price-excluding-tax,
    .weee[data-label] {
        display: block;
        @include lib-font-size(18);
        color: $color-gray07;
        &:before {
            content: '';
            @include lib-font-size(14);
        }
        &:after {
            content: ' ' attr(data-label);
            @include lib-font-size(14);
        }
        .price {
            @include lib-font-size(14);
            color: $color-gray07;
            font-weight: $font-weight__light;
        }
    }
}

@include price-style-1();
@include price-style-3();
