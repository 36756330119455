@import "_variables";

@include lib-font-face(
        $family-name: $icomoon-font-family,
        $font-path: '../fonts/zeta/zeta',
        $font-weight: normal,
        $font-style: normal
);

[class^="zeta-"], [class*=" zeta-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zeta-arrow-circle-left {
  &:before {
    content: $icon-zeta-arrow-circle-left; 
  }
}
.zeta-arrow-circle-right {
  &:before {
    content: $icon-zeta-arrow-circle-right; 
  }
}
.zeta-arrow-down {
  &:before {
    content: $icon-zeta-arrow-down; 
  }
}
.zeta-triangle-right {
  &:before {
    content: $icon-zeta-triangle-right; 
  }
}
.zeta-cart {
  &:before {
    content: $icon-zeta-cart; 
  }
}
.zeta-cart-plus {
  &:before {
    content: $icon-zeta-cart-plus; 
  }
}
.zeta-env {
  &:before {
    content: $icon-zeta-env; 
  }
}
.zeta-grid {
  &:before {
    content: $icon-zeta-grid; 
  }
}
.zeta-list {
  &:before {
    content: $icon-zeta-list; 
  }
}
.zeta-phone {
  &:before {
    content: $icon-zeta-phone; 
  }
}
.zeta-return {
  &:before {
    content: $icon-zeta-return; 
  }
}
.zeta-truck {
  &:before {
    content: $icon-zeta-truck; 
  }
}
.zeta-user {
  &:before {
    content: $icon-zeta-user; 
  }
}

