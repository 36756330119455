//  Newsletter subscription

.block.newsletter {
  margin-bottom: 0;

  .form.subscribe {
    display: table;
    width: 100%;
  }

  .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top;
  }

  .field {
    margin: 0;

    .control {
      display: block;
      padding-top: 15px;
      padding-bottom: 15px;
      &:before {
        position: absolute;
      }
      div.mage-error {
        color: $color-white;
      }
    }
  }

  input {
    height: 36px;
    color: $color-gray03;
  }

  .title {
    display: none;
  }

  .label {
    @extend .abs-visually-hidden;
  }

  .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%;
    .primary {
      border-color: $color-main;
      background:  $color-main;
      padding: 9px 15px;
      min-width: 115px;
      margin-left: 7px;
      &:hover {
        border-color: transparent;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .action.subscribe {
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-left: -1px;
    margin-top: 15px;
    font-size: 12px;
    font-weight: $font-weight__regular;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .block.newsletter {
    width: 80%;
  padding-top: 15px;
  padding-bottom: 15px;
    .field {
      margin-right: 5px;

      .control {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .action.subscribe {
      margin-top: 0;
    }
  }
}
