.cms-index-index {
  .banners-container {
    margin-top: 45px;
  }
}
@include min-screen($screen__m) {
  .cms-index-index {
    .nav-sections {
      margin-right: 15px;
    }
  }
}