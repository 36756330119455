//
//  Typography
//  ---------------------------------------------

$font-family-name__base: 'Roboto';

//
//  Colors
//  ---------------------------------------------

$color-main: #28a5a7;

$color-avail: #64b74f;
