.slider {
  overflow: hidden;
  margin-bottom: 50px;
  img {
    width: 100%;
  }

  .slide {
    position: relative;
    color: $color-gray11;

    span {
      display: block;
      clear: both;
    }

    .left {
      position: absolute;
      left: 0;
      padding-left: 30px;
      top: 35px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;

      .triangle {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 27.5px 0 27.5px 30px;
        border-color: transparent transparent transparent $color-main;
        -webkit-transform: rotate(360deg);
      }

    }

    .right {
      position: absolute;
      right: 0;
      padding-right: 30px;
      top: 35px;
      text-align: right;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;

      .triangle {
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 27.5px 30px 27.5px 0;
        border-color: transparent $color-main transparent transparent;
        -webkit-transform: rotate(360deg);
      }
    }

    .big {
      font-size: 20px;
      line-height: 20px;
      font-weight: $font-weight__bold;
      color: $color-black02;
    }

    .big2 {
      font-size: 20px;
      line-height: 20px;
      font-weight: $font-weight__light;
    }

    .small {
      font-size: 12px;
      font-weight: $font-weight__regular;
      background: $color-main;
      color: $color-white;
      padding: 2px 5px;
      display: inline-block;
      margin-top: 15px;
    }

    a {
      &.readmore {
        @extend .transition-all;
        display: inline-block;
        margin-top: 30px;
        font-weight: $font-weight__bold;
        line-height: 1;
        font-size: 13px;
        border: 1px solid $color-black02;
        color: $color-black02;
        padding: 4px 8px;
        text-transform: uppercase;

        &:hover {
          color: $color-white;
          background: $color-gray11;
        }
      }
    }

    &.slick-active {
      .left, .right {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
      }
    }
  }
  &.slick-initialized {
    display: block;
  }
}

@include min-screen($screen__m) {
  .slider {
    .slide {
      .left {
        top: 45px;
        padding-left: 40px;
        .triangle {
          border-width: 27.5px 0 27.5px 30px;
        }
      }

      .right {
        top: 45px;
        padding-right: 40px;
        .triangle {
          border-width: 27.5px 30px 27.5px 0;
        }
      }

      .big {
        font-size: 25px;
        line-height: 25px;
      }

      .big2 {
        font-size: 25px;
        line-height: 25px;
      }

      .small {
        font-size: 13px;
        padding: 4px 8px;
      }

      a {
        &.readmore {
          padding: 5px 10px;
        }
      }
    }
  }
}


@include min-screen($screen__l) {
  .slider {
    .slide {
      .left {
        top: 100px;
        padding-left: 55px;
        .triangle {
          border-width: 32.5px 0 32.5px 35px;
        }
      }

      .right {
        top: 100px;
        padding-right: 55px;
        .triangle {
          border-width: 32.5px 35px 32.5px 0;
        }
      }

      .big {
        font-size: 30px;
        line-height: 30px;
      }

      .big2 {
        font-size: 30px;
        line-height: 30px;
      }

      .small {
        font-size: 14px;
        margin-top: 30px;
      }

      a {
        &.readmore {
          margin-top: 65px;
          line-height: 14px;
          font-size: 14px;
          padding: 8px 35px;
        }
      }
    }
  }
}