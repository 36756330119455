@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/roboto/roboto-v20-latin_latin-ext-300',
    $font-weight: $font-weight__light,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/roboto/roboto-v20-latin_latin-ext-regular',
    $font-weight: $font-weight__regular,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/roboto/roboto-v20-latin_latin-ext-700',
    $font-weight: $font-weight__semibold,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/roboto/roboto-v20-latin_latin-ext-900',
    $font-weight: $font-weight__bold,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
