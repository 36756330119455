.gallery-placeholder {
    border: 12px solid $color-light-grey;
    .loading-mask {
        position: static;
        padding: 0 0 50%;
    }

    .loader img {
        position: absolute;
    }
}
