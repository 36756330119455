.login-container, .form-login {
  .g-recaptcha {
    margin-bottom: 10px !important;
  }
}

.required-captcha.checkbox{
  position: absolute;
  display: block;
  visibility: visible;
  overflow: hidden;
  opacity: 0;
  width: 1px;
  height: 1px;
}

.review-form {
  .field-recaptcha {
    margin-bottom: 10px;
  }
}

.form.send.friend .g-recaptcha {
  margin-top: 40px;
}