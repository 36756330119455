
//
//  Colors
//  ---------------------------------------------

$color-red01: #e6456b;
$color-red02: #ff4132;
$color-dark: #304151;
$color-dark02: #626262;
$color-dark03: #313131;
$color-dark04: #314152;
$color-black02: #1b1b1b;
$color-footer-line: #687685;
$color-light-grey: #fafafa;
$color-light-grey-2: #cdcdcd;
$color-light-grey-3: #dcdcdc;
$color-light-grey-4: #c7c7c7;
$color-gray03: #959595;
$color-gray04: #fafafa;
$color-gray05: #bcbcbc;



//
//  Button
//  ---------------------------------------------

$button-primary__background: $color-dark04;
$button-primary__border: 1px solid $color-dark04;
$button-primary__hover__background: $color-dark04;
$button-primary__hover__border: 1px solid $color-dark04;
$_button-padding: 7px;


//
//  Pages
//  ---------------------------------------------

$pager__font-size: 17px;
$pager__font-weight: $font-weight__semibold;
$pager__line-height: 25px;
$pager-item__padding: 6px 10px;
$pager-current__border: none;
$pager-current__color: $color-white;
$pager-current__background: $color-main;
$pager-current__font-weight: $font-weight__semibold;
$pager__color: $color-dark01;
$pager__visited__color: $color-dark01;
$pager__hover__color: $color-dark01;
$pager__active__color: $color-dark01;
$pager-action__border: none;
$pager-icon__previous-content: '\f0d9';
$pager-icon__next-content: '\f0da';
$pager-icon__font: 'Font Awesome 5 Free';
$pager-action__color: $color-main;
$pager-action__hover__color: $color-main;
$pager-icon__font-size: 22px;



